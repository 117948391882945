/* @media (max-width: 1023px) {
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
} */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr * {
  font-family: 'Noto Sans KR', sans-serif;
}

/* nomal */
body,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
}

.bg-gray {
  background-color: gray;
}

.bg-gray {
  background: #f5f6f9;
}

/* custom */
:root {
  --header-width: 1400px;
  --header-height: 80px;
  --header-mobile-height: 50px;
  --detail-width: 1200px;
  --detail-right-width: 350px;
  --slick-height: 350px;
}

@media (max-width: 1023px) {
  :root {
    --header-height: 60px;
  }
}

:root {

  --page-width: 1200px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
}

.block {
  display: block !important;
}

.none {
  display: none !important;
}

.none-pc {
  display: none !important;
}

.none-mo {
  display: block !important;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline !important;
}

.visible {
  visibility: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

.max-h100vh {
  max-height: 100vh !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
  font-weight: bold !important;
}

.br-pc {
  display: block;
}

.br-mo {
  display: none;
}

@media (max-width: 1023px) {
  .br-pc {
    display: none !important;
  }

  .br-mo {
    display: block !important;
  }

  .none-pc {
    display: block !important;
  }

  .none-mo {
    display: none !important;
  }
}

/* ======================================================== */
/* Header  */

.header {
  height: var(--header-height);
  /* 평상시에 height를 초과한 서브메뉴가 안보이게 hidden */
  /* overflow: hidden; */
  background-color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  position: fixed;
  width: 100%;
  z-index: 500;
  transition: all .4s;
  border-bottom: 1px solid #13AC67;
}

/* .header-changed {
  background: rgba(30, 30, 30, .9);
} */

/* .header:hover {
  height: 250px;
  background-color: #F3FBF7B2;
} */

.header__con {
  height: var(--header-height);
  /* max-width: var(--header-width); */
  width: 100%;
  margin: 0 auto;
  /* padding: var(--page-padding-mo); */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  color: #000;
  z-index: 300 !important;

  position: relative;
}

.header__left-con {
  height: 100%;
  display: flex;
  align-items: center;
}

.header__logo-con {
  height: 100%;
  display: flex;

  position: absolute;
  left: 5vw;
  top: 0;
}

.header__logo-con>li {
  height: 100%;
}

.header__logo-con>li>a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logo-con>li>a > img {
  height: 40%;
}

.header__category-con {
  /* margin-left: 100px; */
  height: 100%;
  display: flex;

  position: absolute;
  left: 20vw;
  top: 0;
}

.header__category-con>li {
  height: 100%;
  width: 130px;
  position: relative;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
}

.header__category-con>li>a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__hamburger {
  display: none;
}



/* .header__con:hover,  .header__category-con > li > a {
  border-bottom: 2px solid #fff;
} */

.header__category-con>li:hover>a,
.header__category-after {
  color: #13AC67;
  border-bottom: 2px solid #13AC67;
}

.header__btn-wrap {
  width: 110px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  line-height: 13px;
  font-weight: 200;
  letter-spacing: -0.5px;
  color: #fff;

  background-color: #13AC67;
  border-radius: 30px;

  cursor: pointer;

  position: absolute;
  right: 5vw;
  top: 50%;
  transform: translateY(-50%);
}

.header-sub__con {
  position: absolute;
  top: 100%;
  width: 100vw;
  height: 0;
  background-color: #F3FBF7B2;
  overflow: hidden;
  transition: height .5s;
}

.header-sub__con-after {
  height: 140px;
}

.header-sub__category-con {
  padding: 5px 0;

  width: 130px;
  position: relative;
  left: 20vw;
}

.header-sub__category-con a {
  margin: 20px 0;
  display: block;
  text-align: center;
  font-size: 15px;
  color: #000;
  line-height: 15px;
  font-weight: 300;
  transition: all .1s;
}

.header-sub__category-con a:hover {
  color: #000;
  font-weight: 600;
  border-radius: 10px;
}



.header-block {
  height: var(--header-height);
}


@media (max-width: 1023px) {
  .header {
    display: none;
  }
  .header-unclick {
    height: 60px !important;
  }

  .header__con {
    height: 60px;
    display: block;
  }

  .header__logo-con {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
  }

  .header__logo>a {
    font-size: 22px;
  }

  .header__hamburger {
    display: block;
  }

  .header__category-con,
  .header-sub__category-con,
  .header__btn-wrap {
    display: none;
  }


  .header-block {
    height: 0;
  }
}

/* ======================================================== */
.header-mo-bar {
  display: none;
}
.header-mo-bar-block {
  height: 0;
}

@media (max-width: 1023px) {
  .header-mo-bar-block {
    height: 60px;
  }
  .header-mo-bar {
    padding: 0 20px;
    display: block;
    height: var(--header-height);
    position: fixed;
    width: 100%;
    z-index: 500;
    transition: all .4s;
  }

  .header-mo-bar-changed {
    background-color: #13AC67;
  }

  .header-mo-bar__clicked {
    background-color: #fff;
    border-bottom: 1px solid #13AC67;
  }
  .header-mo-bar__clicked .header-mo-bar__hamburger img {
    margin-right: 3px;
    width: 15px;
  }

  .header-mo-bar-unclick {
    height: 60px !important;
  }

  .header-mo-bar__con {
    height: 60px;
    width: 100%;
    margin: 0 auto;
    color: #000;
    z-index: 300 !important;
  
    position: relative;
  }

  .header-mo-bar__logo-con {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;  
  }

  .header-mo-bar__logo-con>li {
    height: 100%;
  }
  
  .header-mo-bar__logo-con>li>a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header-mo-bar__logo-con>li>a > img {
    height: 50%;
  }

  .header-mo-bar__logo>a {
    font-size: 22px;
  }

  .header-mo-bar__hamburger {
    display: block;
  }
  .header-mo-bar__hamburger img {
    width: 25px;
    object-fit: contain;
  }
}


/* ======================================================== */

.header-mo {
  display: none;
}

@media (max-width: 1023px) {
  .header-mo {
    display: block;
    position: fixed;
    width: 100%;
    height: calc(100vh - var(--header-height));
    left: 0;
    top: -120%;
    z-index: 100;
    transition: top .4s ease-in;
    background-color: #F3FBF7;
  }

  .top-height {
    top: var(--header-height);
  }

  .header-mo__category-con {
    margin-top: 15px;
  }

  .header-mo__category-con > li > a {
    width: 100%;
  }

  .header-mo__category-con > li:last-child > a {
    border: none;
  }

  .header-mo__category-con > li > a {
    display: inline-block;
    padding: 15px 30px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }

  .header-mo__dropdown > ul {
    display: none;
  }

  .header-mo__dropdown:hover > ul {
    display: block;
  }
  .header-mo__dropdown:hover > a {
    color: #13AC67;
  }

  .header-mo__dropdown > ul > li > a {
    display: inline-block;
    padding: 10px 60px;
    font-size: 16px;
    font-weight: 400;
    color: #4c4c4c;
  }

  .header-mo__btn-wrap {
    margin: 30px 0 0 15px;

    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-size: 12px;
    line-height: 12px;
    font-weight: 200;
    letter-spacing: -0.5px;
    color: #fff;
  
    background-color: #13AC67;
    border-radius: 50px;
  
    cursor: pointer;  
  }
}

/* ======================================================== */

.section-one {
  padding: 100px 0;
}

.section-one__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
}


.section__txt-title,
.section__txt-sub {
  font-size: 35px;
  line-height: 35px;
  letter-spacing: -0.5px;
  font-weight: 600;
  text-align: center;
}

.color-green {
  color: #13AC67;
}

.bg-lightgreen {
  background-color: #f4f9f7;
  ;
}

.section__txt-sub {
  padding-top: 20px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
}

.section__txt-line {
  margin: 0 auto;
  margin-bottom: 30px;
  width: 3px;
  height: 80px;
  background: #3DA874;
}

@media (max-width: 1023px) {
  .section-one {
    padding: 65px 0;
  }

  .section-one__con {
    padding: var(--page-padding-mo);
    max-width: 600px;
  }

  .section__txt-title {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -1px;
    /* font-weight: 700; */
  }

  .section__txt-sub {
    padding-top: 20px;
    /* font-weight: 400; */
  }

  .section__txt-line {
    /* margin: 0 auto; */
    margin-bottom: 20px;
    width: 3px;
    height: 60px;
    /* background: #3DA874; */
  }
}

/* ======================================================== */

.intro-hero {
  height: calc(100vh - var(--header-height));
  position: relative;
}

.intro-hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.intro-hero__bg video {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: absolute;
  object-fit: cover;
}
@media all and (max-aspect-ratio:19/16) {
  .intro-hero__bg video {
      /* width: auto; */
      /* height: 100vh; */
      /* left: -120%; */
      /* transform: translateX(-50%); */
   }
}


/* .intro-hero__bg-gray {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.intro-hero__bg>img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.intro-hero__txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

}

.intro-hero__txt-con {
  width: 100%;
  padding-left: 8%;
  color: #fff;
}

.intro-hero__txt-title {
  font-size: 50px;
  line-height: 70px;
  font-weight: 200;
  color: #fff;
}

.intro-hero__txt-title>span {
  font-weight: 400;
  color: #fff;
}

.intro-hero__txt-sub {
  margin-top: 15px;
  font-size: 25px;
  line-height: 25px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  word-break: keep-all;
}

.intro-hero__btn-con {
  margin-top: 100px;
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
}

.intro-hero__btn-con li {
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin: 10px 10px;
  padding-left: 20px;
  width: 280px;
  height: 100px;
  transition: all 0.3s ease-in;

  border: 4px solid rgba(255, 255, 255, 0.5);
}

.intro-hero__btn-con li:nth-child(3) {
  visibility: hidden;
}

.intro-hero__btn-con li>div:nth-child(1) {
  vertical-align: top;
  font-size: 12px;
  line-height: 22px;
  font-weight: 300;

}

.intro-hero__btn-con li>div:nth-child(2) {
  font-size: 25px;
  line-height: 35px;
  font-weight: 300;
}

@media (max-width: 1023px) {
  .intro-hero {
    height: 100vh;
  }

  .intro-hero__bg>img {
    background-position: (-50%, -50%);
  }

  .intro-hero__txt {
    justify-content: flex-end;
  }

  .intro-hero__txt-con {
    padding-left: 8%;
    padding-bottom: 10vh;
  }

  .intro-hero__txt-title {
    font-size: 35px;
    line-height: 50px;
  }

  .intro-hero__txt-title>span {
    display: block;
    margin-top: 10px;
  }

  .intro-hero__txt-sub {
    margin-top: 20px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
  }

  .intro-hero__btn-con {
    display: none;
  }
}

/* ========================================================= */
.intro-service {
  background-color: #F7F7F7;
}

.intro-service__img-con {
  padding-top: 60px;
  display: flex;
  margin: 0 -10px;
}

.intro-service__img-item {
  box-sizing: border-box;
  /* background-size: 105%; */
  background-size: cover;
  background-position: center;
  transition: background-size .3s ease;

  width: calc(25% - 20px);
  margin: 0 10px;
  position: relative;
}

.intro-service__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  /* background-color: rgba(0,0,0,0.3); */
  background-image: linear-gradient(to bottom, #13AC670F 6%, #0000004D 100%);
}

.intro-service__img-item::after {
  content: '';
  display: block;
  padding-bottom: 135%;
}


.intro-service__img-txt-con {
  width: 80%;
  position: absolute;
  bottom: 40px;
  left: 30px;
  color: white;
  z-index: 100;
}

.intro-service__img-txt-con>li:nth-child(1) {
  font-size: 16px;
  line-height: 26px;
  font-weight: 200;
}

.intro-service__img-txt-con>li:nth-child(2) {
  margin-top: 10px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

@media (max-width: 1023px) {
  .intro-service__img-con {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }


  .intro-service__img-txt-con {
    bottom: 20px;
    left: 15px;
    color: white;
    z-index: 100;
  }

  .intro-service__img-txt-con>li:nth-child(1) {
    font-size: 13px;
    line-height: 20px;
  }

  .intro-service__img-txt-con>li:nth-child(2) {
    margin-top: 5px;
    font-size: 18px;
    line-height: 18px;
  }

  .intro-service__img-item {
    width: calc(50% - 10px);
    margin: 5px;
  }

  .intro-service__img-item::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

/* ========================================================= */

.intro-board__slick-con {
  margin: 0 auto;
  margin-top: 60px;
  width: 90%;
  height: var(--slick-height);
  position: relative;
}

.intro-board__slick-con * {
  pointer-events: auto;
}

.intro-board__slick {
  width: 100%;
  height: 100%;
}

/* li와 동일 */
.intro-board__slick .slick-slide {
  height: var(--slick-height);
  padding: 10px 15px;
}

.intro-board__slick .slick-slide>div {
  height: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 7px;
  cursor: pointer;
}

.intro-board__slick .slick-slide>div img {
  width: 100%;
  aspect-ratio: 16/10;
  /* height: calc(var(--slick-height) * 0.50) !important; */
  object-fit: cover;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.intro-board__slick .slick-slide ul {
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro-board__slick .slick-slide ul>li:nth-child(1) {
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
}

.intro-board__slick .slick-slide ul>li:nth-child(2) {
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #7f7f7f;

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* word-break: keep-all; */
}

.intro-board__slick .slick-slide ul>li:nth-child(2) p {
  margin: 0;
}

.intro-board__slick .slick-slide ul>li:nth-child(3) {
  margin-top: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  color: #7f7f7f;
}


.intro-board__slick .slick-dots {
  bottom: -40px;
}

.intro-board__slick .slick-dots li button:before,
.intro-board__slick .slick-active button:before {
  color: #000 !important;
}

.intro-board__slick-arrow-con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro-board__slick-arrow-con>div {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  box-sizing: border-box;
  cursor: pointer;
}

.intro-board__slick-arrow-con>div:nth-of-type(1) {
  left: -60px;
}

.intro-board__slick-arrow-con>div:nth-of-type(2) {
  right: -60px;
  text-align: right;
}

.intro-board__slick-arrow-con>div>img {
  width: 20px;
}

@media (max-width: 1023px) {
  .intro-board__slick-con {
    margin-top: 40px;
    width: 85%;
  }

  .intro-board__slick .slick-slide ul {
    padding: 30px 20px;
  }

  .intro-board__slick .slick-slide ul>li:nth-child(2) {
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
  }

  .intro-board__slick .slick-dots {
    display: none !important;
  }

  .intro-board__slick-arrow-con>div:nth-of-type(1) {
    left: -20px;
  }

  .intro-board__slick-arrow-con>div:nth-of-type(2) {
    right: -20px;
    text-align: right;
  }

  .intro-board__slick-arrow-con>div>img {
    width: 15px;
  }
}

/* ========================================================= */

.intro-license {
  background-color: #13AC671A;
}

.intro-license .section__txt-con {
  margin-bottom: 60px;
}

.intro-license__btn-con {
  position: relative;
  margin: 0px auto 40px auto;

  width: 100%;
  max-width: 250px;
  height: 45px;
  font-size: 18px;
  color: #000;

  border-radius: 35px;
  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.intro-license__btn {
  position: absolute;
  left: 0%;
  top: 0;
  width: 50%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-weight: 400;

  border-radius: 30px;
  background-color: #13AC67;
  z-index: 2;
  transition: all .3s ease-in;
}

.intro-license__btn-txt {
  padding: 0 25px;
}

.intro-license__img-con {
  display: flex;
  justify-content: center;
}

.intro-license__img-con>img {
  margin: 0 20px;
  width: calc((100% - (40px * 3)) / 3);
}

@media (max-width: 1023px) {
  .intro-license .section__txt-title>span {
    margin-top: 5px;
    display: block;
    font-weight: 300;
  }

  .intro-license .section__txt-con {
    margin-bottom: 40px;
  }

  .intro-license__btn-con {
    display: none;
  }

  .intro-license__img-con>img {
    margin: 0 5px;
    width: calc((100% - (10px * 3)) / 3);
  }
}

/* ========================================================= */

.submenu {
  margin: 0 auto;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submenu > li {
  padding: 15px 30px;
  
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;

  position: relative;
  cursor: pointer;
}


.submenu-clicked {
  color: #13AC67;
}
.submenu-clicked:after {
  content: "";
  display: block;

  width: 50px;
  border-bottom: 2px solid #13AC67;

  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}

@media (max-width: 1023px) {
  .submenu {
    margin-bottom: 5px;
    height: 60px;
    border-bottom: 2px solid #D9D9D9;
  }
  
  .submenu > li {
    padding: 20px 20px;
  }
  .submenu > li:last-child {
    margin-right: 20px;
  }
  
  .submenu-clicked:after {
    width: 80px;
  }
}

/* ========================================================= */

.history__con {
  display: flex;
}

.history__txt {
  padding: 80px 0;
  width: 53%;
  height: auto;
  background-color: #13AC67;

  display: flex;
  justify-content: center;
}

.history__txt-con {
}

.history__txt-title {
  font-size: 40px;
  line-height: 60px;
  font-weight: 100;
  color: #fff;
}
.history__txt-title > span {
  font-weight: 500;
}

.history__txt-sub {
  margin-top: 40px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 100;
  color: #fff;
}
.history__txt-sub > span {
  font-weight: 400;
}

.history__list-con {
  padding: 60px 60px;
  width: 47%;
}

.history__list-con > li {
  padding-left: 20px;
  border-left: 2px solid #0000001A;
}
.history__list-con > ul {
  padding-left: 20px;
  /* border-left: 2px solid #0000001A; */
  border-left: 2px solid #0000002A;
}


.history__list-con > ul > li {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #13AC67;

  position: relative
}

.history__list-con > ul > li::before {
  content: "";
  display: block;

  padding: 5px;
  border: 2px solid #13AC67;
  border-radius: 50px;
  background-color: #fff;

  position: absolute; /* 원본에 relative 필수 */
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
}

.history__list-con > ul > ul > li {
  padding: 5px 0;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.5px;
  font-weight: 400;
}

.history__list-con > ul > ul > li:first-child {
  padding-top: 15px;
}
.history__list-con > ul > ul > li:last-child {
  padding-bottom: 30px;
}

@media (max-width: 1023px) {
  .history__con {
    flex-direction: column;
  }
  
  .history__txt {
    padding: 60px 20px;
    width: 100%;
  }
    
  .history__txt-title {
    font-size: 30px;
    line-height: 40px;
  }

  .history__list-con {
    padding: 40px 30px;
    width: 100%;
  }
  
  .history__list-con > li {
    line-height: 10px;
  }

  .history__list-con > ul > ul > li {
    padding: 4px 0;
    font-size: 14px;
    line-height: 18px;
  }
  
  .history__list-con > ul > ul > li:first-child {
    padding-top: 10px;
  }
  .history__list-con > ul > ul > li:last-child {
    padding-bottom: 20px;
  }
}

/* ========================================================= */

.people {
  background-color: #F3FBF7;
}
.people .section-one__con {
  max-width: 900px;
}
.people .section__txt-title {
  text-align: left;
}

.people__img-wrap {
  margin-top: 60px;
}
.people__img-wrap > img {
  width: 100%;
}

@media (max-width: 1023px) {
  .people__img-wrap {
    margin-top: 40px;
  }
    .people .section-one__con {
    max-width: 600px;
  }  
}
/* ========================================================= */

.ceo__con {
  padding: 30px 0 60px 0;
  display: flex;
  position: relative;
}

.ceo__txt {
  margin-right: 30px;
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.ceo__txt-title {
  font-size: 35px;
  line-height: 50px;
  font-weight: 600;
}
.ceo__txt-title > span {
  color: #13AC67;
}

.ceo__txt-title .quotes {
  /* display: inline-block; */
  font-size: 60px;
  line-height: 30px;
  vertical-align: text-bottom;
}

.ceo__txt-title .mg-left {
  display: inline-block;
  margin-left: 45px;
}

.ceo__txt-sub {
  margin-top: 60px;

  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
}
.ceo__txt-sub > span {
  margin-top: 30px;
  display: inline-block;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  color: #13AC67;
}

.ceo__img-wrap {
  width: 40%;
  display: flex;
  align-items: center;
}

.ceo__img-wrap > img {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

@media (max-width: 1023px) {
  .ceo__con {
    padding: 60px 20px;
    flex-direction: column;
  }
  
  .ceo__txt {
    margin-right: 0px;
    width: 100%;
  }

  .ceo__txt-title {
    font-size: 30px;
    line-height: 45px;
    font-weight: 500;
  }
  .ceo__txt-title .quotes {
    display: inline-block;
    font-size: 50px;
    line-height: 30px;
  }
  .ceo__txt-title .quotes:last-child {
    /* display: none; */
  }
  .ceo__txt-title .mg-left {
    margin: 0;
  }
  
  
  .ceo__txt-sub {
    margin-top: 300px;
  
    font-size: 16px;
    line-height: 26px;
  }
  .ceo__txt-sub > span {
    margin-top: 15px;
    font-size: 20px;
    line-height: 20px;
  }
  
  .ceo__img-wrap {
    width: 200px;

    position: absolute;
    top: 130px;
    right: 0;
  }
  
  .ceo__img-wrap > img {
    /* width: 100%; */
    /* height: 600px; */
    /* object-fit: contain; */
  }
}

/* ========================================================= */

.service {
  padding: 0 0 60px 0;
}

.service .section-one__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
}

.service__box-con {
  margin: 0 -20px;
  display: flex;
  flex-wrap: wrap;
}

.service__box-con > li {
  margin: 20px;
  padding: 40px 30px;
  width: calc(50% - 40px);
  aspect-ratio: 16/9;

  /* border: 2px solid #000; */
  border-radius: 10px;
  position: relative;
}

.service__box-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #13AC67;
}
.service__box-sub {
  margin-top: 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}
.service__box-sub > span {
  font-weight: 500;
}
.service__box-more {
  margin-top: 30px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600; 
  color: #13AC67;
}

.service__box-con > li > img {
  width: 110px;
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.service__box-con > li:nth-child(1),
.service__box-con > li:nth-child(4) {
  border: 2px solid #13AC67;
}
.service__box-con > li:nth-child(1) > .service__box-title,
.service__box-con > li:nth-child(1) > .service__box-sub {
  margin-left: 30%;
}
.service__box-con > li:nth-child(1) > img {
  top: 30%;
  left: 15px;
}
.service__box-con > li:nth-child(4) > img {
  top: 40%;
  left: 30px;
}

.service__box-con > li:nth-child(2) {
  background-color: #13ac6712;
}

.service__box-con > li:nth-child(3),
.service__box-con > li:nth-child(6) {
  background-color: #d9d9d944;
}

.service__box-con > li:nth-child(4) > .service__box-sub {
  margin-left: 40%;
}

.service__box-con > li:nth-child(5) {
  background-color: #13AC67;
}

.service__box-con > li:nth-child(5) > .service__box-title,
.service__box-con > li:nth-child(5) > .service__box-sub {
  color: #fff;
}
.service__box-con > li:nth-child(5) > .service__box-sub {
  font-weight: 100;
}

@media (max-width: 1023px) {
  .service .section-one__con {
    padding: var(--page-padding-mo);
  }
  
  .service__box-con {
    margin: 0;
  }
  
  .service__box-con > li {
    margin: 10px auto;
    padding: 20px;
    width: 100%;
    max-width: 350px;
  }
  
  .service__box-title {
    font-size: 18px;
    line-height: 28px;
  }
  .service__box-sub {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
  }
  .service__box-more {
    margin-top: 25px;
    font-size: 14px;
    line-height: 14px;
  }
  
  .service__box-con > li > img {
    width: 75px;
    bottom: 30px;
    right: 25px;
  }
  
  .service__box-con > li:nth-child(1) > .service__box-title,
  .service__box-con > li:nth-child(1) > .service__box-sub {
    margin-left: 0;
  }
  .service__box-con > li:nth-child(1) > img,
  .service__box-con > li:nth-child(4) > img {
    top: auto;
    left: auto;
    bottom: 30px;
    right: 25px;
  }

  .service__box-con > li:nth-child(4) > .service__box-sub {
    margin-left: 0;
  }  
}

/* ======================================================== */
.contact .section__txt-title {
  text-align: left;
  line-height: 50px;
}
.contact .section__txt-title > span {
  font-size: 30px;
  font-weight: 300;
}

.contact__content-con {
  margin-top: 60px;
  display: flex;
  /* justify-content: space-between; */
}

.contact__map-wrap {
  width: 35%;
  height: 350px;
  background-color: #afafaf;
}

.contact__txt-con {
  margin-top: 20px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.contact__txt-con > li {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  color: #13AC67; 
}

.contact__txt-con > ul {
  margin-top: 25px;
}

.contact__txt-con > ul > li {
  display: flex;
}

.contact__txt-con > ul > li:nth-child(1) {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.contact__txt-con > ul > li:nth-child(2) {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

@media (max-width: 1023px) {
  .contact .section__txt-title {
    line-height: 35px;
  }
  .contact .section__txt-title > span {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 25px;
  }
  
  .contact__content-con {
    margin-top: 60px;
    flex-direction: column-reverse;
  }
  .contact__map-wrap {
    width: 100%;
    height: 300px;
  }

  .contact__txt-con {
    margin: 0;
    margin-bottom: 30px;
    word-break: keep-all;
  }
  

}

/* ========================================================= */

.board__box-con {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}

.board__box-item {
  margin: 10px;
  padding: 30px 20px;
  width: calc(25% - 20px);
  aspect-ratio: 16/12;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 2px solid #0000001c;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.board__box-menu {
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  color: #13AC67;
}
.board__box-title {
  margin-top: 15px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* word-break: keep-all; */
}
.board__box-date {
  margin-top: 35px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  color: #8e8e8e;
}

.board__box-img-con {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.board__box-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  z-index: -3;
}

.board__bg-gray {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  border-radius: 5px;
  z-index: -2;
}

.sns-item * {
  color: #fff;
}

.board__search-con {
  margin: 60px 0 30px 0;
  display: flex;
  justify-content: space-between;
}

.board-mo__select {
  display: block;
  padding: 10px 15px;
  color: #000 !important;
  background-color: #fff !important;
  border: 2px solid #e9e9e9;
  border-radius: 5px;
}
.board-mo__select * {
  color: #000 !important;
  background-color: #fff !important;
}

.board-mo__search-wrap {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  width: 300px;
  border: 2px solid #e9e9e9;
  border-radius: 5px;
}
.board-mo__search-wrap > input {
  font-size: 16px;
  border: none;
  outline: none;
}

.board-more {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.board-more span {
  padding: 10px 45px;
  border: 2px solid #e9e9e9;
  border-radius: 5px;

  cursor: pointer;
}

.board .board__page-arrow {
  display: flex;
  align-items: center;
}
.board .board__page-number .board__page-number-item {
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

@media (max-width: 1023px) {  
  .board .submenu {
    display: none;
  }

  .board .board__con {
    padding: 0 40px;
  }

  .board .section__txt-con {
    display: flex;
    justify-content: space-between;
  }

  .board__search-con {
    display: none;
  }
  .board-mo__select {
    padding: 10px 15px;
  }
  .board-mo__search-wrap {
    margin-top: 40px;
    padding: 15px 15px;
    width: 100%;
    display: flex !important;
    justify-content: space-between;
  }

  

  .board__box-con {
    margin-top: 30px;
    justify-content: center;
  }

  .board__box-item {
    max-width: 400px;
    width: 100%;
  }

  .board .board__page-number {
    display: flex;
    align-items: center;
  }
  
}


/* ========================================================== */

.board-detail {
  padding: 40px 0;
}

.board-detail__con {
  padding: var(--page-padding-mo);
  margin: 0 auto;
  width: 100%;
  /* max-width: var(--page-width); */
  max-width: 900px;
}

.board-detail__header-title{
  border-top: 2px solid #474747;
  padding-top: 40px;
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
  /* text-align: left; */
  text-align: center;
  color: #434242;
  word-break: keep-all;
}

.board-detail__header-info {
  padding: 30px 0 40px 0;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;

  font-size: 14px;
  line-height: 14px;
  color: #696969;
  border-bottom: 1px solid #d3d1d1;
}

.board-detail__header-info > ul {
  display: flex;
}

.board-detail__header-info > ul > li:first-child {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #afafaf;
}


.board-detail__content-con {
  padding: 30px 0;
  border-bottom: 1px solid #d3d1d1;
}

.board-detail__content-item > pre {
  font-size: 15px;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif;

  /* pre 태그의 내용이 삐져나옴 방지 */
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.board-detail__content-item > pre img {
  max-width: 100%;
}

.board-detail__btn-list {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.board-detail__btn-list > a {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 50px;
  background-color: #13AC67;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -1px;
}


@media (max-width:834px) {
  .board-detail {
    padding: 25px 0;
  }
  
  .board-detail__header-title{
    margin-top: 15px;
    font-size: 20px;
  }

  .board-detail__header-info {
    padding: 15px 0 20px 0;
    font-size: 12px;
    line-height: 12px;
  }

  .board-detail__header-info > ul > li:first-child {
    padding-right: 10px;
    margin-right: 10px;
  }
  
  .board-detail__content-con {
    padding: 20px 0;
  }

  .board-detail__content-item {
    font-size: 15px;
  }
}

/* ======================================================== */

.admin {
  position: relative;
  min-height: 100vh;
}

.admin > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ======================================================== */

.admin-signin__con {
  position: absolute;
  padding: 0 30px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 340px;
  width: 100%;
  height: 500px;
  background-color: #f5f4f4;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.admin-signin__con > img {
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
  object-fit: cover;
}

.admin-signin__input-item {
  width: 100%;
  display: flex;
  justify-content: center;
}

.signup2__answer-input {
  margin-top: 60px;
  padding: 10px 0;
  width: 100%;
  border: none;
  border-radius: 10px;
  outline: none;
  font-size: 18px;
  color: #fafafa;
  text-indent: 10px;
  background-color: #afafaf;
}

.admin-signin__btn {
  margin-top: 15px;
  padding: 10px 0;
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;

  display: flex;
  justify-content: center;
  background-image: linear-gradient(to right, #6f9fe1 0%, #3c6fc1 100%);
  cursor: pointer;
}

@media (max-width: 1023px) {
  .admin-signin__con {
    max-width: 300px;
    height: 450px;
  }
}

/* ======================================================== */

.admin-list__con {
  position: absolute;
  padding: 40px 30px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 100%;
  min-height: 550px;
  background-color: #f5f4f4;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.admin-list__btn-con {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1px;
}

.admin-list__btn-con > div {
  margin: 1px 1px;
  width: calc((100%)/3 - 2px);
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 600;
  color: #fff;

  background-image: linear-gradient(to right, #acabb1 0%, #acabb1 100%);
  cursor: pointer;
}

.admin-list__btn-after {
  background-image: linear-gradient(to right, #6f9fe1 0%, #3c6fc1 100%) !important;
}


@media (max-width: 1023px) {
  .admin-list__con {
    padding: 25px 25px;
    max-width: 350px;
    min-height: 450px;
  }

  .admin-list__btn-con > div {
    height: 40px;
    font-size: 12px;
  }
}

/* ======================================================== */

.admin-list__title {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 22px;
  font-weight: 600;
}

.admin-list__more {
  position: relative;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
}

.admin-list__more::after {
  position: absolute;
  left: 100%;
  top: 39%; 
  content: '';
  width: 7px; /* 사이즈 */
  height: 7px; /* 사이즈 */
  border-top: 1px solid #000; /* 선 두께 */
  border-right: 1px solid #000; /* 선 두께 */
  transform: rotate(45deg); /* 각도 */
}

.admin-list__more > label {
  cursor: pointer;
}

.admin-list__table {
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* table 내 말줄임 (...) 기능 (1/4) */
}

.admin-list__thead > tr > th {
  text-align: left;
  font-size: 16px;
  line-height: 36px;
  font-weight: 500;
  color: #646464;
  border-bottom: 2px solid rgba(0,0,0,0.3);
}

/* 표 하단 선 */
.admin-list__tbody > tr:last-child {
  border-bottom: 2px solid rgba(0,0,0,0.3);
}

.admin-list__th01 {
  width: 23%;
}
.admin-list__th02 {
  width: 100%;
}
.admin-list__th03 {
  width: 10%;
}

.admin-list__tbody {
  position: relative;
}


.admin-list__tbody > tr {
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.admin-list__tbody > tr > td {
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: -1px;


  /* table 내 말줄임 (...) 기능 (2~4/4) */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.admin-list__tbody > tr > td:nth-child(2) {
  font-weight: 500;
}

.admin-list__tbody > tr > td > a {
  cursor: pointer;
}

.board__page-number {
  display: flex;
  justify-content: center;
}

.board__page-number > div {
  margin-top: 20px;
  margin-right: 10px;

  font-size: 15px;
  cursor: pointer;
}




@media (max-width: 1023px) {
  .intro-board__title {
    margin-top: 30px;
  }

  .admin-list__table {
    /* display: none; */
  }
  .admin-list__th01 {
    width: 35%;
  }
  .admin-list__th02 {
    width: 100%;
  }
  .admin-list__th03 {
    width: 15%;
  }
  .admin-list__thead > tr > th {
    font-size: 12px;
    line-height: 22px;
  }
  .admin-list__tbody > tr > td {
    font-size: 12px;
    line-height: 32px;
  }
}

/* ======================================================== */

.admin-list__img-con {
margin-top: 10px;
display: flex;
flex-wrap: wrap;
justify-content: center;
border-top: 2px solid #afafaf;
}

.admin-list__img-wrap {
margin: 10px 40px;
width: 120px;
}

.admin-list__img-wrap > img {
width: 100%;
aspect-ratio: 16/12;
object-fit: contain;
}

.admin-list__img-wrap-txt {
display: flex;
justify-content: space-around;
}

@media (max-width: 1023px) {
.admin-list__img-wrap {
  margin: 10px 10px;
  width: calc(25%);
}

.admin-list__img-wrap > img {
  width: 100%;
  aspect-ratio: 16/12;
  object-fit: contain;
}


}

/* ======================================================== */

.admin-write__con {
  position: absolute;
  padding: 30px 30px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  width: 100%;
  min-height: 550px;
  background-color: #f5f4f4;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

@media (max-width: 1023px) {
  .admin-write__con {
    padding: 20px 25px;
    max-width: 350px;
    min-height: 450px;
  }
}

/* =========================================================== */

.react-quill > * {
  font-family: 'Noto Sans KR', sans-serif !important;
}

.board-write__con {
  padding: var(--page-padding-mo);
  margin: 0 auto;
  width: 100%;
  /* max-width: var(--page-width); */
  max-width: 700px;
}

.board-write__board-name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.5px;
  
  display: flex;
  justify-content: space-between;
}

.board-write__header{
  border-bottom: 1px solid #d3d1d1;
}

.board-write__select-con {
  margin-top: 15px;

  display: flex;
  justify-content: space-between;
}

.board-write__select-con select {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #4f4f4f !important;
  border: 1px solid #5b5b5b !important;
  border-radius: 5px;
  background-color: #f5f4f4 !important;
}

.board-write__new-menu {
  width: 150px;
  font-size: 15px;
  font-weight: 400;
  color: #434242;
  border: 2px solid #a5a3a3;
  white-space: pre;

  color: #4f4f4f !important;
  background-color: #f5f4f4 !important;
  font-family: 'Noto Sans KR', sans-serif;
  resize: none;
}

.board-write__header-title{
  margin: 15px 0;
  color: #434242;
  border: 2px solid #a5a3a3;
}

.board-write__content-con {
  padding-top: 15px;
}

.board-write__warning {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #6a6a6a;
}

.board-write__content-item {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  color: #434242;
  border: 2px solid #a5a3a3;
  white-space: pre;
}

.board-write__header-input,
.board-write__content-input{
  padding-left: 15px;
  min-width: 95%;
  border: none;
  outline: none;
  font-size: 15px;
  font-family: 'Noto Sans KR', sans-serif;
  resize: none;
  background-color: transparent;
}

.board-write__content-input{
  min-height: 300px;
}

.board-write__content-files label {
  margin-top: 3px;
  margin-right: 10px;
  padding: 2px 5px;

  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: #4e4e4e;

  background-color: #e9e7e7;
  border: 1px solid #111;
  border-radius: 3px;
  cursor: pointer;
}

.board-write__content-files input {
  display: none;
}

.board-write__content-files span {
  margin-right: 10px;
}

.board-write__btn-submit{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.board-write__btn-submit label {
  margin-right: 5px;
}

.board-write__btn-submit > span{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 40px;

  font-size: 14px;
  font-weight: 600;
  color: #fff;

  background-image: linear-gradient(to right, #6f9fe1 0%, #3c6fc1 100%);
  border-radius: 10px;
  cursor: pointer;
}

.board-write__btn-before > span {
  background-color: #afafaf;
  box-shadow: none;
  cursor: not-allowed;
}



@media (max-width:834px) {
  .board-write {
    padding: 20px 0;
  }

  .board-write__header-title{
    margin-top: 10px;
    font-size: 20px;
  }
  
  .board-write__content-con {
    padding-top: 15px;
  }

  .board-write__content-item {
    font-size: 15px;
  }

  .board-write__btn-submit{
    margin-top: 10px;
  }
  
  .board-write__btn-submit > span{
    width: 70px;
    height: 35px;
  
    font-size: 13px;

    border-radius: 7px;
  }
}

/* ========================================================= */

.footer {
  /* margin-top: 30px; */
  padding: 20px 0;

  /* custom */
  background: #F7F7F7;
  color: rgba(0,0,0,0.3);

}

.footer__con {
  /* padding: var(--page-padding-pc); */
  margin: 0 auto;
  width: 100%;
  /* max-width: var(--page-width); */

  /* display: flex; */
  position: relative;
}

.footer__logo {
  margin: 10px 8vw 30px 8vw;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: white;

  font-weight: bold;
}

.footer__con > hr {
  background: rgba(0,0,0,0.1);
  height: 2px;
  border: 0;
}

.footer__logo > img {
  width: 100%;
}

.footer__txt-con {
  margin: 30px 8vw;
}

.footer__txt01 {
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
}

.footer__txt02 {
  font-size: 14px;
}

.footer__icon-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 30px;
}
.footer__icon-con > a {
  margin-left: 15px;
  width: 40px;
}
.footer__icon-con > a > img {
  width: 100%;
}

.footer__law-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
}

@media (max-width: 1023px) {
  .footer {
      /* padding-bottom: 50px; */
  }
  .footer__con {
      /* padding: var(--page-padding-tab); */
      flex-direction: column;
  }
  .footer__logo {
      /* margin-bottom: 10px; */
      justify-content: left;
      font-size: 24px;
  }
  .footer__txt01 {
      font-size: 12px;
      line-height: 20px;
  }
  
  .footer__txt02 {
      font-size: 14px;
  }

  .footer__icon-con {
    right: 20px;
    bottom: auto;
    top: 0;
  }
  .footer__law-con {
    right: 20px;
    bottom: -30px;
    font-size: 12px;
  }
}
/* ========================================================= */

.license__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
}

.license__grid-con {
  margin: 100px 0;
  display: flex;
  flex-wrap: wrap;
}

.license__grid-item {
  margin: 30px 10px 30px 10px;
  width: calc((100% - 2 * 20px) / 2);
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
  position: relative;
}

.license__source-name {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1px;
  color: #428ef9;
  cursor: pointer;
}

.license__source-url {
  letter-spacing: -1px;
  opacity: 0.7;
  font-weight: 400;
  color: darkgray;
  text-decoration: underline;
}

.license__source-type {
  letter-spacing: -1px;
  opacity: 0.7;
}


.license__source-writer {
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 22px;
}

.license__txt-item {
  padding: 30px 0;
  border-top: 1px solid #afafaf;
}

.license__txt-title {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.license__txt-sub {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 300;
  color: #222222;
}


@media (max-width: 1023px) {
  .license__con {
    padding: var(--page-padding-tab);
  }

  .license__txt-sub {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .license__con {
    padding: var(--page-padding-mo);
  }

  .license__grid-item {
    margin: 15px 0px 15px 0px;
    margin-bottom: 15px;
    width: calc((100% - 2 * 10px) / 2);
  }

  .license__grid-item:nth-child(2n-1) {
    margin-right: 20px;
  }

  .license__source-name {
    font-size: 18px;
  }


  .license__source-type {
    font-size: 15px;
  }

  .license__source-writer {
    font-size: 15px;
    font-weight: 400;
  }


}

@media (max-width: 480px) {}